
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'kt-add-entity',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      url: '',
      employee_url: '',
      load: false,
      uniqueID: false,
      dialogVisible: false,
      alertText: false,
      entity: {
        entity_type_id: '',
        entity_email: '',
        entity_telephone: '',
        entity_industry_sector: '',
        entity_web_url: '',
        entity_address: '',
        entity_postcode: '',
        entity_division: '',
        entity_district: '',
        entity_sub_district: '',
        entity_description: '',
        active_status: '1',
        status_remarks: '',
        entity_fax: '',
        entity_name: '',
        entity_short_name: '',
        entity_registration_number: '',
        entity_registration_date: '',
        entity_registration_authority: '',
        entity_logo: '',
        entity_is_bill_author: 0,
      },
      employee: {
        employee_father_name: '',
         company:'',
        employee_mother_name: '',
        employee_name: '',
        role: '',
        designation: '',
        employee_dob: '',
        employee_nid: '',
        employee_passport: '',
        employee_driving_license: '',
        employee_mobile: '',
        employee_email: '',
        employee_gender: '',
        employee_religion: '',
        employee_present_address: '',
        employee_present_district: '',
        employee_permanent_address: '',
        employee_permanent_district: '',
        employee_img: '',
        user_name: '' as any,
    
      },
      user: {
        password: '',
        password_confirmation: '',
        active_status: '1',
      },

      contact_person: {
        image: '',
      },
      entityTypes: [],
      companies: [] as any,
      tranche: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      empDesignation: [],
      loading: false,
      showentityInstitute: false,
    };
  },
  async created() {
    await this.getCompany();
  },
  methods: {
    isBillAuthorCheck(event) {
      if (event.target.checked) {
        this.entity.entity_is_bill_author = 1;
        console.log(this.entity.entity_is_bill_author);
      } else {
        this.entity.entity_is_bill_author = 0;
        console.log(this.entity.entity_is_bill_author);
      }
    },
    updatePhoto(e) {
      this.entity.entity_logo = e.target.files[0];
      this.url = URL.createObjectURL(this.entity.entity_logo);
    },
    employeePhoto(e) {
      this.employee.employee_img = e.target.files[0];
      this.employee_url = URL.createObjectURL(this.employee.employee_img);
    },

    async formSubmit() {
      let formData = new FormData();

      // formData.append("file_name", this.file_name);
      formData.set('employee_img', this.employee.employee_img);
      let user_id = VueCookieNext.getCookie('_cpv_user');

      formData.set('user_id', user_id.id);

      for (var key in this.employee) {
        formData.set(key, this.employee[key]);
      }
      for (var key in this.user) {
        formData.set(key, this.user[key]);
      }
      for (var key in this.entity) {
        formData.set(key, this.entity[key]);
      }

      this.loading = true;
      await ApiService.post('company/save', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              (this.url = ''),
                (this.employee_url = ''),
                (this.entity = {
                  entity_type_id: '',
                  entity_email: '',
                  entity_telephone: '',
                  entity_industry_sector: '',
                  entity_web_url: '',
                  entity_address: '',
                  entity_postcode: '',
                  entity_division: '',
                  entity_district: '',
                  entity_sub_district: '',
                  entity_description: '',
                  active_status: '1',
                  status_remarks: '',
                  entity_fax: '',
                  entity_name: '',
                  entity_short_name: '',
                  entity_registration_number: '',
                  entity_registration_date: '',
                  entity_registration_authority: '',
                  entity_logo: '',
                  entity_is_bill_author: 0,
                });
              this.employee = {
                employee_father_name: '',
                employee_mother_name: '',
                employee_name: '',
                company:'' ,
                role: '',
                designation: '',
                employee_dob: '',
                employee_nid: '',
                employee_passport: '',
                employee_driving_license: '',
                employee_mobile: '',
                employee_email: '',
                employee_gender: '',
                employee_religion: '',
                employee_present_address: '',
                employee_present_district: '',
                employee_permanent_address: '',
                employee_permanent_district: '',
                employee_img: '',
                 user_name: '',
              };
              this.user = {
                password: '',
                password_confirmation: '',
                active_status: '1',
              };
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getEntityTypes() {
      await ApiService.get('configurations/entity_type/list')
        .then((response) => {
          this.entityTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCompany() {
      let company = VueCookieNext.getCookie('_company_id');
      let data = '';
      if (company !== 'null') {
        data = company;
      }

      await ApiService.get('company/list?company=' + data)
        .then((response) => {
          this.companies = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },


    //    async getUniqueID() {
    //   let company = VueCookieNext.getCookie('_company_id');
    //   let data = '';
    //   if (company !== 'null') {
    //     data = company;
    //   }
    //     else {
    //     data = this.employee.company;
    //   }
    //      this.uniqueID = true;
    //   await ApiService.get('user/unique_id?company=' + data)
    //     .then((response) => {

    //       this.employee.unique_id = response.data.data.code;
    //       console.log(response.data.data);
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    
    
    async getDivision() {
      await ApiService.get('configurations/geo_division/list')
        .then((response) => {
          this.divisions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get(
        'configurations/geo_district/list?division=' +
        this.entity.entity_division
      )
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployeeDesignation() {
      await ApiService.get(
        'entity_type_role/role/allroles?entity_type_id=' +
        this.entity.entity_type_id
      )
        .then((response) => {
          this.empDesignation = response.data.data.role;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?division=' +
        this.entity.entity_division +
        '&district=' +
        this.entity.entity_district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      // email: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      // web_url: Yup.string().required().label("Web Url"),
      // postcode: Yup.string().required().max(4).label("Postcode"),
      // registration_number: Yup.string().required().label("Registration Number"),
      // registration_authority: Yup.string()
      //   .required()
      //   .label("Registration Authority"),
      // telephone: Yup.number().required().min(11).label("Telephone"),
      // entity_name: Yup.string().required().label("Name"),
      // entity_short_name: Yup.string().required().label("Short Name"),
      // employeeEmail: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      // entityEmail: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      // //presentDistrict: Yup.string().required().label("Present District"),
      // name: Yup.string().required().label("Name"),
      // // designation: Yup.string().required().label("Designation"),
      // gender: Yup.string().required().label("Gender"),
      // mobile: Yup.number().required().min(11).label("Mobile"),
      // dob: Yup.string().required().label("Date of Birth"),
      // password: Yup.string()
      //   .required()
      //   .matches(
      //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      //   )
      //   .label("Password"),
      // confirmPassword: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Password didn't match!")
      //   .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
